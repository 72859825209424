'use client'

import BodyText from '@lyra/core/components/BodyText'
import Heading from '@lyra/core/components/Heading'
import PageCentered from '@lyra/core/components/PageCentered'

export default function NotFound() {
  return (
    <PageCentered>
      <Heading size="h1">404</Heading>
      <BodyText size="editorial" color="secondary">
        Page not found
      </BodyText>
    </PageCentered>
  )
}
